input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

a {
    color: @text  !important;

    &:hover {
        color: @dark-blue  !important;
    }
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, .35) !important;
}

.ant-modal {
    min-width: 500px;

    &.ocms-storage-quote-form-modal {
        min-width: 600px;
    }

    &.ocms-table-modal {
        .ant-modal-content  {
            .ant-modal-body {
                padding: 12px 0 18px !important;
                .ant-table {
                    .ant-table-title {
                        padding: 0 18px 12px !important;
                    }
                    .ant-table-body {
                        table {
                            thead {
                                th {
                                    .react-resizable-handle {
                                        &.react-resizable-handle-se {
                                            &:before {
                                                top: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr:nth-last-child(1) {
                                    td {
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                    .ant-table-placeholder {
                        border: none;
                    }
                }
            }
        }
    }

    .ant-modal-content {
        min-width: 500px;
        border-radius: @radius;

        .ant-modal-header {
            padding: 15px 18px 0 !important;
            height: 50px !important;
            border-top-left-radius: @radius;
            border-top-right-radius: @radius;

            .ant-modal-title {
                font-size: @sFont;
                font-weight: normal;
            }
        }

        .ant-modal-body {
            padding: 18px !important;

            .ant-modal-confirm-body {
                .ant-modal-confirm-content {
                    margin-top: 25px;
	                margin-left: 0 !important;
                    font-size: @xsFont;
                }
            }

            .ant-table {
                .ant-table-title {
                    .ocms-modal-operation-box {
                        overflow: hidden;
                    }

                    .ant-input-search {
                        width: 230px;
                    }
                }
            }

            .ant-pagination.ant-table-pagination {
                margin: 16px 0 0;
            }
        }

        .ant-modal-footer {
            padding: 15px 18px;
            height: 60px !important;
            border-radius: 0;
        }
    }
}

.ant-btn {
    font-size: @xsFont  !important;
    border-radius: @radius !important;

    &.ant-btn-lg {
        padding: 0 36px !important;
    }

    &.ant-btn-sm {
        padding: 0 18px !important;
        height: 30px !important;
    }

    &.ant-btn-primary {
        color: @white;
        background-color: @light-blue;
        border: none;

        &:hover {
            color: @white;
            background-color: @blue;
        }

        // https://github.com/ant-design/ant-design/issues/13098
        // https://www.zhihu.com/question/306858501/answer/559312342
        // https://zhuanlan.zhihu.com/p/53214213
        // Will always remember this fucking christmas egg that is introduced
        // in a code push in September of 2018 with a perfect commit message:
        // "This will not display in change-log".
        /*
        &.christmas {
            &:before {
                display: none !important;
            }
        }
        */
    }

    &.ocms-btn-gray {
        color: silver;
        background-color: #ebebeb;
        border: none;

        &:hover {
            color: @light-black;
            background-color: @blue-gray;
        }
    }

    &.ant-btn-danger {
        font-size: @xsFont  !important;
        color: @white;
        background-color: @red;
        border: none;
        border-radius: @radius;

        &:focus {
            color: @white;
            background-color: @red;
        }
    }

    &.ant-btn-warning {
        color: @white;
        background-color: @warn-btn;
        border: none;

        &:hover {
            color: @white;
            background-color: @warn-btn-hover;
        }

        &:focus {
            color: @white;
            background-color: @warn-btn;
        }
    }

    &.ant-btn-primary[disabled],
    &.ant-btn-primary[disabled]:hover,
    &.ant-btn-primary[disabled]:focus,
    &.ocms-btn-gray[disabled],
    &.ocms-btn-gray[disabled]:hover,
    &.ocms-btn-gray[disabled]:focus,
    &.ant-btn-danger[disabled],
    &.ant-btn-danger[disabled]:hover,
    &.ant-btn-danger[disabled]:focus,
    &.ant-btn-warning[disabled],
    &.ant-btn-warning[disabled]:hover,
    &.ant-btn-warning[disabled]:focus {
        color: rgba(0, 0, 0, 0.25);
        background-color: @page-bg;
        border-color: #d9d9d9;
        text-shadow: none;
    }

    &.ant-btn-circle {
        padding: 0 !important;
        width: 32px !important;
        height: 32px !important;
        font-size: 16px !important;
        border-radius: 50% !important;

        &.ant-btn-sm {
            padding: 0 !important;
            width: 24px !important;
            height: 24px !important;
            font-size: 14px !important;
            border-radius: 50% !important;
        }
    }
}

.ant-popover {
    font-size: @xsFont  !important;

    .ant-popover-inner {
        border-radius: @radius;
        /*
        .ant-popover-inner-content {
            max-width: 300px;
        }
        */
    }
}

.ant-form {
	label {
		font-size: 12px !important;
	}

    .ant-row {
        font-size: @xsFont;

        &:nth-last-child(1) {
            &.ant-form-item {
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }

        &.ant-form-item {
            margin-bottom: 5px !important;

            .ant-form-item-label {
                white-space: normal !important;

                label {
                    // float: left;
                    font-size: @xsFont;

                    &>.anticon {
                        font-size: @xsFont;
                        vertical-align: -1px !important;
                    }
                }
            }
        }
    }
}

.ant-form-explain {
    font-size: @xsFont  !important;
}

.ant-input {
    border-radius: @radius !important;

    &.ant-input-sm {
        height: 30px;
        font-size: @xsFont;
    }
}

.ant-input-number {
    border-radius: @radius !important;

    &.ant-input-number-sm {
        height: 30px;
        font-size: @xsFont;

        .ant-input-number-input {
            height: 28px;
        }
    }
}

textarea {
    &.ant-input {
        padding: 7px;
        font-size: @xsFont;
    }
}

.ant-input-group-wrapper {
    &.ant-input-group-wrapper-sm {
        .ant-input-group {
            .ant-input-group-addon {
                font-size: @xsFont;
                border-radius: @radius;
            }
        }
    }
}

.ant-select {
    &.ant-select-sm {
        font-size: @xsFont;

        .ant-select-selection--single {
            height: 30px;

            .ant-select-selection__rendered {
                line-height: 30px;
            }
        }

        .ant-select-selection--multiple {
            min-height: 30px;

            .ant-select-selection__rendered {
                line-height: 30px;

                .ant-select-selection__placeholder {
                    margin-left: 2px;
                }

                ul {
                    .ant-select-selection__choice {
                        margin-top: 4px;
                    }

                    .ant-select-selection__choice,
                    .ant-select-search.ant-select-search--inline {
                        height: 22px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.ant-select-selection {
    border-radius: @radius !important;
    border: 1px solid #bbbbbb !important;

    .ant-select-selection-selected-value {
        span {
            display: inline-block;
            width: 105%;
            .ocms-text-overflow-ellipsis();
        }
    }

    .ant-select-selection__choice__remove {
        &:hover {
            color: @light-blue  !important;
        }
    }
}

.ant-form-item-control {
    &.has-error {
        .ant-select-selection {
            border-color: #f5222d !important;
        }
    }
}

.ant-select-dropdown {
    .ant-select-dropdown-menu-item {
        font-size: @xsFont;

        span {
            display: inline-block;
            width: 105%;
            .ocms-text-overflow-ellipsis();
        }
    }
}

.ant-input-search {
    .ant-input {
        border: 1px solid #bbbbbb;
        &.ant-input,
        &.ant-input-lg {
            padding-left: 18px;
            font-size: 12px;
        }
    }

    .ant-input-suffix {
        right: 18px !important;
    }
}


.ant-menu {
    overflow: hidden;

    &.ant-menu-inline,
    &.ant-menu-vertical {
        border-right: none !important;
    }

    .ant-menu-item .anticon,
    .ant-menu-submenu-title .anticon {
        margin-right: 8px !important;
    }

    &.ant-menu-inline .ant-menu-submenu-title,
    .ant-menu-item {
        width: 100% !important;
        height: 50px !important;
        margin: 0 0 !important;
        line-height: 50px !important;
        font-size: 13px !important;
        text-overflow: clip !important;
    }

    &.ant-menu-inline .ant-menu-submenu-title {
        padding-left: 12px !important;
        padding-right: 12px;
        .ant-menu-submenu-arrow {
            right: 8px;
        }
    }

    .ant-menu-item {
        padding-left: 12px !important;
    }

    .ant-menu-sub {
        .ant-menu-item {
            padding-left: 40px !important;
        }
    }

    &.ant-menu-dark,
    .ant-menu-dark {
        background-color: @light-black !important;

        .ant-menu-item {
            background-color: @light-black;
        }

        .ant-menu-submenu {
            .ant-menu-submenu-title {
                background-color: @light-black !important;
            }

            .ant-menu-store.subscribe,
            .ant-menu-inline {
                background-color: @light-black !important;
            }
        }
    }
}

.ant-tabs {
    .ant-tabs-bar {
        &.ant-tabs-top-bar {
            padding: 0 18px;
            margin-bottom: 0;
        }
    }
    .ant-tabs-nav {
        .ant-tabs-tab {
            padding: 14px 0 13px;
            &.ant-tabs-tab-active {
                font-weight: normal;
            }
        }
    }
}

.ant-select {
    font-size: 12px;

    .ant-select-selection-selected-value {
        font-weight: normal !important;
    }
}

.ant-select-dropdown {
    font-size: @xsFont;
}

.ant-switch {
    &.ant-switch-checked {
        background-color: @green;
    }
}

.ant-table-wrapper {
    .ant-table {
        &.ant-table-small {
            font-size: @xsFont  !important;
            border: none !important;

            &>.ant-table-content>.ant-table-body>table {
                padding: 0;

                td {
                    height: 40px;
                }
            }
        }

        .ant-table-title {
            top: 0;
            padding: 13px 18px;
            font-size: @sFont;
            color: @title;
            border-bottom: 1px solid @light-border;
            .ocms-clear-fix();
        }

        .ant-table-thead {
            font-size: 12px;

            &>tr {
                &>th {
                    padding: 12px 18px;
                    font-size: @xsFont;
                    border: none;
                    background-color: @white  !important;

                    &>span {
                        color: @title;
                    }
                }
            }
            .react-resizable {
                position: relative;
                background-clip: padding-box;
                user-select: none;
                &:hover {
                    .react-resizable-handle {
                        &:before {
                            content: "<>";
                            position: absolute;
                            top: 14px;
                            color: @light-blue;
                        }
                    }
                }
                .react-resizable-handle {
                    position: absolute;
                    width: 22px;
                    height: 100%;
                    bottom: 0;
                    right: 0;
                    cursor: col-resize;
                    z-index: 1;
                }
            }
        }

        .ant-table-body {
            table {
                // 'table-layout: fixed' will let table calculate its width based
                // on the set value of every td column, not their actual width widened
                // by their contents when 'table-layout' is 'automatic'. So 'table-layout'
                // is necessary here in order for the setting 'text-overflow: ellipsis'
                // of 'td.ellipsis' take effect.
                table-layout: fixed;
                max-width: 100%;

                tr.ant-table-row {
                    font-size: @xsFont;

                    &:nth-child(odd) {
                        background-color: @table-row-stripe-bg;
                    }

                    &:nth-last-child(1) {
                        td {
                            border-bottom: 1px solid @table-row-stripe-bg;
                        }
                    }

                    td {
                        border-bottom: none;
                        // The minimum height to show 15 table rows without scrollbar is 900px.
                        @media screen and (max-height: 1080px) {
                            padding: 11px 18px;
                            height: 46px;
                        }
                        @media screen and (max-height: 970px) {
                            padding: 9px 18px;
                            height: 41px;
                        }
                        @media screen and (max-height: 940px) {
                            padding: 9px 18px;
                            height: 41px;
                        }
                        @media screen and (max-height: 920px) {
                            padding: 8px 18px;
                            height: 40px;
                        }
                    }
                }
            }
        }

        .ant-table-footer {
            padding: 8px 16px;
        }
    }

    .ant-table-placeholder {
        margin-top: 8px;
        font-size: 10px;
        border-top: none;
    }
}

.ant-calendar-picker {
    &.ant-calendar-picker-small {
        .ant-calendar-picker-input {
            &.ant-input-sm {
                height: 30px;
            }

            .ant-calendar-range-picker-separator {
                vertical-align: baseline;
            }
        }
    }
}

.ant-pagination {
    &.ant-table-pagination {
        display: flex;
        padding: 0 18px;
        width: 100%;

        .ant-pagination-total-text {
            flex: 1;
            font-size: @xsFont;
            line-height: 32px;

            span {
                padding-left: 10px;
            }
        }

        .ant-pagination-prev {
            margin-left: 20px;
        }

        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next {
            min-width: 28px;
            height: 28px;
            line-height: 28px;

            .ant-pagination-item-link {
                height: 28px;

                &:before,
                &:after {
                    height: 28px;
                }
            }
        }
    }
}

.anticon {
    &.ocms-option-node {
        margin-right: 15px;
    }
}

.ant-spin {
    .ant-spin-text {
        margin-top: 80px;
        font-size: 18px;
    }
}

.ant-notification {
    font-size: @xsFont;

    .ant-notification-notice-message {
        font-size: @sFont;
    }

    .ant-notification-notice-description {
        font-size: @xsFont;
    }
}

.ant-steps {
    .ant-steps-item-description {
        font-size: @xsFont;
    }
}

.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
    -webkit-animation-name: none !important;
    animation-name: none !important;
}

.show-help-leave.show-help-leave-active {
    -webkit-animation-name: none !important;
    animation-name: none !important;
}

.show-help-enter,
.show-help-appear {
    opacity: 1 !important;
}

.ant-tooltip-inner {
    max-width: 1000px !important;
    background-color: #fff !important;
    color: #2d2d2d !important;
    padding: 14px 20px !important;
    border-radius: @radius !important;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
    border-top-color: #fff !important;
}

.ant-divider-inner-text {
    font-size: @xsFont;
    font-weight: normal;
}