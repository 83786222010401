.ocms-top-bar-wrapper {
    width: 100%;
    min-width: 1280px;
    height: 50px;
    font-size: 12px;
    color: @white;
    text-shadow: 1px 1px 2px black;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("../../images/top_bar_bg.png");
    background-size: cover;
    .ocms-platform-copy-right-wrapper {
        float: left;
        height: 50px;
        line-height: 50px;
        .mis-name {
            margin-left: 10px;
        }
        .copy-right {
            margin-left: 117px;
        }
    }
    .ocms-top-info-wrapper {
        float: right;
        padding-right: 10px;
        height: 50px;
        line-height: 50px;
        .ocms-sign-in-user-wrapper {
            user-select: none;
            .ocms-singed-in-user,
            .ocms-reset-password,
            .ocms-sign-out {
                margin-left: 10px;
                &:hover {
                    cursor: pointer;
                    color: @light-blue;
                }
            }
            .ocms-singed-in-user {
                margin-left: 0;
            }
        }
    }
}

.ocms-top-bar-drawer {
    .ant-drawer-wrapper-body {
        .ant-drawer-header {
            background-color: transparent;
            border: none;
            position: relative;
            .ant-drawer-title {
                font-size: @sFont;
                color: @text;
            }
            &:after {
                content: " ";
                position: absolute;
                bottom: 0;
                display: block;
                width: 208px;
                height: 1px;
                border-top: 1px solid @dark-border;
            }
        }
        .ant-drawer-body {
            position: relative;
            height: calc(100% - 55px);
            font-size: @xsFont;
            p {
                margin-bottom: 20px;
                color: @text;
                &.ocms-top-bar-drawer-action-btn {
                    cursor: pointer;
                    user-select: none;
                    &:active,
                    &:hover {
                        color: @light-blue;
                    }
                }
            }
        }
    }
}