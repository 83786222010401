@import url('./fn.less');
@import url('./var.less');

// offline font library for antd v3.3.0
@font-face {
    font-family: 'anticon';
    src: url('./font/font_148784_v4ggb6wrjmkotj4i.eot'); // IE9
    src: // url('./font/font_148784_v4ggb6wrjmkotj4i.woff') format('woff'),  // chrome、firefox、opera、Safari, Android, iOS 4.2+
         url('./font/font_148784_v4ggb6wrjmkotj4i.ttf') format('truetype');  // iOS 4.1-
         // url('./font/font_148784_v4ggb6wrjmkotj4i.svg#iconfont') format('svg');
}

* {
    margin: 0;
    padding: 0;
}

.m-l {
    margin-left: 10px;
}

.m-r {
    margin-right: 10px;
}

.ocms-click-link {
	cursor: pointer;
	text-decoration: underline #646464;
	&:hover {
		color: @blue;
		text-decoration: underline @blue;
	}
}

.ocms-hover-default {
	cursor: default;
}

.ocms-hover-pointer {
    cursor: pointer;
}

.ocms-info-icon {
    &:hover {
        color: @light-blue;
        cursor: pointer;
    }
}

.ocms-file-link {
    &:hover {
        color: @blue;
        cursor: pointer;
    }
}

.ocms-link-product-model-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    // border-bottom: 1px dashed #ddd;
    .ocms-link-product-model-title {
        margin-bottom: 10px;
        font-size: @xsFont;
    }
    .ant-checkbox-wrapper {
        font-size: @xsFont;
        &+ .ant-checkbox-wrapper {
            margin-top: 5px;
            margin-left: 0;
            margin-right: 8px;
        }
    }
}

.ocms-quote-form-selection-row {
    font-size: @xsFont;
    user-select: none;
    .ocms-quote-form-selection {
        margin-right: 10px;
        display: inline-block;
        padding: 1px 7px;
        line-height: 1.5;
        vertical-align: middle;
        cursor: pointer;
        border: 1px solid @border;
        transition: border .3s ease-in;
        &:nth-last-child {
            margin-right: 0;
        }
        &:not(.active) {
            &:hover {
                border: 1px solid #1790ff;
            }
        }
        &.first-number {
            margin-left: 15px;
        }
        &.active {
            color: @white;
            background-color: #1790ff;
        }
        &.ml-0 {
            margin-left: 0;
        }
        &.mr-0 {
            margin-right: 0;
        }
    }
    &.network-card {
        padding-top: 11px;
        .ant-checkbox-wrapper {
            & > span {
                &:nth-last-child(1) {
                    display: inline-block;
                    min-width: 90px;
                }
            }
        }
    }
    &.consumable {
        padding-top: 11px;
        .ant-checkbox-wrapper {
            & > span {
                &:nth-last-child(1) {
                    display: inline-block;
                    min-width: 70px;
                }
            }
        }
    }
    .ant-checkbox-wrapper {
        font-size: @xsFont;
    }
    &.function-service-title {
        margin-top: 5px;
        font-size: @xsFont;
    }
    &.function-desc {
        line-height: @xlFont;
        user-select: auto;
    }
    &.mt-10 {
        margin-top: 10px;
    }
    .ant-checkbox-wrapper {
        &+ .ant-checkbox-wrapper {
            margin-top: 5px;
            margin-left: 0;
            margin-right: 8px;
        }
    }
}

.ocms-quote-form-blue-text {
    color: #1790ff;
}

.ocms-quote-form-number-unit {
    padding-left: 10px;
}