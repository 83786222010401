@keyframes modal-mask-fade-in {
  0% {opacity: 0;}
  100% {opacity: 0.3;}
}

@keyframes drop-down-menu-fade-in {
	0% {opacity: 0; transform: translate3d(0, 10px, 0);}
	100% {opacity: 1; transform: none;}
}

@keyframes carousel-switch-fade-in {
	0% {opacity: .4;}
	100% {opacity: 1;}
}

@keyframes carousel-pagination-progress {
	0% {width: 0;}
	100% {width: 99%;}
}

@keyframes rd-center-point-scale {
    49% {transform: scale(1, 1);}
    55% {transform: scale(1.5, 1.5);}
    61% {transform: scale(1, 1);}
}

@keyframes rotation-y-and-up {
	0%{transform: rotateY(0deg) translateY(0);}
	25%{transform: rotateY(90deg) translateY(-2px);}
	50%{transform: rotateY(180deg) translateY(-2px);}
	75%{transform: rotateY(270deg) translateY(-2px);}
	100%{transform: rotateY(360deg) translateY(0);}
}

@keyframes fade-in-left-right {
	0% {opacity: 0; transform: translate3d(-100%, 0, 0);}
	100% {opacity: 1; transform: none;}
}

@keyframes fade-in-right-left {
	0% {opacity: 0; transform: translate3d(100%, 0, 0);}
	100% {opacity: 1; transform: none;}
}

@keyframes fade-in-bottom-up {
	0% {opacity: 0; transform: translate3d(0, 100%, 0);}
	100% {opacity: 1; transform: none;}
}