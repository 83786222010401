@font-face {
  font-family: 'anticon';
  src: url('./common/font/font_148784_v4ggb6wrjmkotj4i.eot');
  src: url('./common/font/font_148784_v4ggb6wrjmkotj4i.ttf') format('truetype');
}
* {
  margin: 0;
  padding: 0;
}
.m-l {
  margin-left: 10px;
}
.m-r {
  margin-right: 10px;
}
.ocms-click-link {
  cursor: pointer;
  text-decoration: underline #646464;
}
.ocms-click-link:hover {
  color: #3690ff;
  text-decoration: underline #3690ff;
}
.ocms-hover-default {
  cursor: default;
}
.ocms-hover-pointer {
  cursor: pointer;
}
.ocms-info-icon:hover {
  color: #52a7fe;
  cursor: pointer;
}
.ocms-file-link:hover {
  color: #3690ff;
  cursor: pointer;
}
.ocms-link-product-model-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.ocms-link-product-model-item .ocms-link-product-model-title {
  margin-bottom: 10px;
  font-size: 12px;
}
.ocms-link-product-model-item .ant-checkbox-wrapper {
  font-size: 12px;
}
.ocms-link-product-model-item .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-top: 5px;
  margin-left: 0;
  margin-right: 8px;
}
.ocms-quote-form-selection-row {
  font-size: 12px;
  user-select: none;
}
.ocms-quote-form-selection-row .ocms-quote-form-selection {
  margin-right: 10px;
  display: inline-block;
  padding: 1px 7px;
  line-height: 1.5;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #e9e9e9;
  transition: border 0.3s ease-in;
}
.ocms-quote-form-selection-row .ocms-quote-form-selection:nth-last-child {
  margin-right: 0;
}
.ocms-quote-form-selection-row .ocms-quote-form-selection:not(.active):hover {
  border: 1px solid #1790ff;
}
.ocms-quote-form-selection-row .ocms-quote-form-selection.first-number {
  margin-left: 15px;
}
.ocms-quote-form-selection-row .ocms-quote-form-selection.active {
  color: #fff;
  background-color: #1790ff;
}
.ocms-quote-form-selection-row .ocms-quote-form-selection.ml-0 {
  margin-left: 0;
}
.ocms-quote-form-selection-row .ocms-quote-form-selection.mr-0 {
  margin-right: 0;
}
.ocms-quote-form-selection-row.network-card {
  padding-top: 11px;
}
.ocms-quote-form-selection-row.network-card .ant-checkbox-wrapper > span:nth-last-child(1) {
  display: inline-block;
  min-width: 90px;
}
.ocms-quote-form-selection-row.consumable {
  padding-top: 11px;
}
.ocms-quote-form-selection-row.consumable .ant-checkbox-wrapper > span:nth-last-child(1) {
  display: inline-block;
  min-width: 70px;
}
.ocms-quote-form-selection-row .ant-checkbox-wrapper {
  font-size: 12px;
}
.ocms-quote-form-selection-row.function-service-title {
  margin-top: 5px;
  font-size: 12px;
}
.ocms-quote-form-selection-row.function-desc {
  line-height: 20px;
  user-select: auto;
}
.ocms-quote-form-selection-row.mt-10 {
  margin-top: 10px;
}
.ocms-quote-form-selection-row .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-top: 5px;
  margin-left: 0;
  margin-right: 8px;
}
.ocms-quote-form-blue-text {
  color: #1790ff;
}
.ocms-quote-form-number-unit {
  padding-left: 10px;
}
@keyframes modal-mask-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes drop-down-menu-fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes carousel-switch-fade-in {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes carousel-pagination-progress {
  0% {
    width: 0;
  }
  100% {
    width: 99%;
  }
}
@keyframes rd-center-point-scale {
  49% {
    transform: scale(1, 1);
  }
  55% {
    transform: scale(1.5, 1.5);
  }
  61% {
    transform: scale(1, 1);
  }
}
@keyframes rotation-y-and-up {
  0% {
    transform: rotateY(0deg) translateY(0);
  }
  25% {
    transform: rotateY(90deg) translateY(-2px);
  }
  50% {
    transform: rotateY(180deg) translateY(-2px);
  }
  75% {
    transform: rotateY(270deg) translateY(-2px);
  }
  100% {
    transform: rotateY(360deg) translateY(0);
  }
}
@keyframes fade-in-left-right {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-right-left {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-bottom-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps__rail-x {
  position: absolute;
  bottom: 0;
  display: none;
  height: 4px;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
}
.ps__rail-y {
  position: absolute;
  right: 0;
  display: none;
  width: 4px;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}
.ps__thumb-x {
  position: absolute;
  bottom: 2px;
  height: 4px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
}
.ps__thumb-y {
  position: absolute;
  right: 2px;
  width: 4px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  height: 11px;
  background-color: #999;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 11px;
  background-color: #999;
}
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 6px !important;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 6px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
a {
  color: #646464 !important;
}
a:hover {
  color: #1890ff !important;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.35) !important;
}
.ant-modal {
  min-width: 500px;
}
.ant-modal.ocms-storage-quote-form-modal {
  min-width: 600px;
}
.ant-modal.ocms-table-modal .ant-modal-content .ant-modal-body {
  padding: 12px 0 18px !important;
}
.ant-modal.ocms-table-modal .ant-modal-content .ant-modal-body .ant-table .ant-table-title {
  padding: 0 18px 12px !important;
}
.ant-modal.ocms-table-modal .ant-modal-content .ant-modal-body .ant-table .ant-table-body table thead th .react-resizable-handle.react-resizable-handle-se:before {
  top: 12px;
}
.ant-modal.ocms-table-modal .ant-modal-content .ant-modal-body .ant-table .ant-table-body table tbody tr:nth-last-child(1) td {
  border: none;
}
.ant-modal.ocms-table-modal .ant-modal-content .ant-modal-body .ant-table .ant-table-placeholder {
  border: none;
}
.ant-modal .ant-modal-content {
  min-width: 500px;
  border-radius: 6px;
}
.ant-modal .ant-modal-content .ant-modal-header {
  padding: 15px 18px 0 !important;
  height: 50px !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 14px;
  font-weight: normal;
}
.ant-modal .ant-modal-content .ant-modal-body {
  padding: 18px !important;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 25px;
  margin-left: 0 !important;
  font-size: 12px;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-table .ant-table-title .ocms-modal-operation-box {
  overflow: hidden;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-table .ant-table-title .ant-input-search {
  width: 230px;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-pagination.ant-table-pagination {
  margin: 16px 0 0;
}
.ant-modal .ant-modal-content .ant-modal-footer {
  padding: 15px 18px;
  height: 60px !important;
  border-radius: 0;
}
.ant-btn {
  font-size: 12px !important;
  border-radius: 6px !important;
}
.ant-btn.ant-btn-lg {
  padding: 0 36px !important;
}
.ant-btn.ant-btn-sm {
  padding: 0 18px !important;
  height: 30px !important;
}
.ant-btn.ant-btn-primary {
  color: #fff;
  background-color: #52a7fe;
  border: none;
  /*
        &.christmas {
            &:before {
                display: none !important;
            }
        }
        */
}
.ant-btn.ant-btn-primary:hover {
  color: #fff;
  background-color: #3690ff;
}
.ant-btn.ocms-btn-gray {
  color: silver;
  background-color: #ebebeb;
  border: none;
}
.ant-btn.ocms-btn-gray:hover {
  color: #2d2d2d;
  background-color: #cfdae7;
}
.ant-btn.ant-btn-danger {
  font-size: 12px !important;
  color: #fff;
  background-color: #f6787a;
  border: none;
  border-radius: 6px;
}
.ant-btn.ant-btn-danger:focus {
  color: #fff;
  background-color: #f6787a;
}
.ant-btn.ant-btn-warning {
  color: #fff;
  background-color: #ffaa3b;
  border: none;
}
.ant-btn.ant-btn-warning:hover {
  color: #fff;
  background-color: #ff9a16;
}
.ant-btn.ant-btn-warning:focus {
  color: #fff;
  background-color: #ffaa3b;
}
.ant-btn.ant-btn-primary[disabled],
.ant-btn.ant-btn-primary[disabled]:hover,
.ant-btn.ant-btn-primary[disabled]:focus,
.ant-btn.ocms-btn-gray[disabled],
.ant-btn.ocms-btn-gray[disabled]:hover,
.ant-btn.ocms-btn-gray[disabled]:focus,
.ant-btn.ant-btn-danger[disabled],
.ant-btn.ant-btn-danger[disabled]:hover,
.ant-btn.ant-btn-danger[disabled]:focus,
.ant-btn.ant-btn-warning[disabled],
.ant-btn.ant-btn-warning[disabled]:hover,
.ant-btn.ant-btn-warning[disabled]:focus {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f7f8fc;
  border-color: #d9d9d9;
  text-shadow: none;
}
.ant-btn.ant-btn-circle {
  padding: 0 !important;
  width: 32px !important;
  height: 32px !important;
  font-size: 16px !important;
  border-radius: 50% !important;
}
.ant-btn.ant-btn-circle.ant-btn-sm {
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
  font-size: 14px !important;
  border-radius: 50% !important;
}
.ant-popover {
  font-size: 12px !important;
}
.ant-popover .ant-popover-inner {
  border-radius: 6px;
  /*
        .ant-popover-inner-content {
            max-width: 300px;
        }
        */
}
.ant-form label {
  font-size: 12px !important;
}
.ant-form .ant-row {
  font-size: 12px;
}
.ant-form .ant-row:nth-last-child(1).ant-form-item {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.ant-form .ant-row.ant-form-item {
  margin-bottom: 5px !important;
}
.ant-form .ant-row.ant-form-item .ant-form-item-label {
  white-space: normal !important;
}
.ant-form .ant-row.ant-form-item .ant-form-item-label label {
  font-size: 12px;
}
.ant-form .ant-row.ant-form-item .ant-form-item-label label > .anticon {
  font-size: 12px;
  vertical-align: -1px !important;
}
.ant-form-explain {
  font-size: 12px !important;
}
.ant-input {
  border-radius: 6px !important;
}
.ant-input.ant-input-sm {
  height: 30px;
  font-size: 12px;
}
.ant-input-number {
  border-radius: 6px !important;
}
.ant-input-number.ant-input-number-sm {
  height: 30px;
  font-size: 12px;
}
.ant-input-number.ant-input-number-sm .ant-input-number-input {
  height: 28px;
}
textarea.ant-input {
  padding: 7px;
  font-size: 12px;
}
.ant-input-group-wrapper.ant-input-group-wrapper-sm .ant-input-group .ant-input-group-addon {
  font-size: 12px;
  border-radius: 6px;
}
.ant-select.ant-select-sm {
  font-size: 12px;
}
.ant-select.ant-select-sm .ant-select-selection--single {
  height: 30px;
}
.ant-select.ant-select-sm .ant-select-selection--single .ant-select-selection__rendered {
  line-height: 30px;
}
.ant-select.ant-select-sm .ant-select-selection--multiple {
  min-height: 30px;
}
.ant-select.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered {
  line-height: 30px;
}
.ant-select.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__placeholder {
  margin-left: 2px;
}
.ant-select.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered ul .ant-select-selection__choice {
  margin-top: 4px;
}
.ant-select.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered ul .ant-select-selection__choice,
.ant-select.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered ul .ant-select-search.ant-select-search--inline {
  height: 22px;
  line-height: 20px;
}
.ant-select-selection {
  border-radius: 6px !important;
  border: 1px solid #bbbbbb !important;
}
.ant-select-selection .ant-select-selection-selected-value span {
  display: inline-block;
  width: 105%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-selection .ant-select-selection__choice__remove:hover {
  color: #52a7fe !important;
}
.ant-form-item-control.has-error .ant-select-selection {
  border-color: #f5222d !important;
}
.ant-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 12px;
}
.ant-select-dropdown .ant-select-dropdown-menu-item span {
  display: inline-block;
  width: 105%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-input-search .ant-input {
  border: 1px solid #bbbbbb;
}
.ant-input-search .ant-input.ant-input,
.ant-input-search .ant-input.ant-input-lg {
  padding-left: 18px;
  font-size: 12px;
}
.ant-input-search .ant-input-suffix {
  right: 18px !important;
}
.ant-menu {
  overflow: hidden;
}
.ant-menu.ant-menu-inline,
.ant-menu.ant-menu-vertical {
  border-right: none !important;
}
.ant-menu .ant-menu-item .anticon,
.ant-menu .ant-menu-submenu-title .anticon {
  margin-right: 8px !important;
}
.ant-menu.ant-menu-inline .ant-menu-submenu-title,
.ant-menu .ant-menu-item {
  width: 100% !important;
  height: 50px !important;
  margin: 0 0 !important;
  line-height: 50px !important;
  font-size: 13px !important;
  text-overflow: clip !important;
}
.ant-menu.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 12px !important;
  padding-right: 12px;
}
.ant-menu.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: 8px;
}
.ant-menu .ant-menu-item {
  padding-left: 12px !important;
}
.ant-menu .ant-menu-sub .ant-menu-item {
  padding-left: 40px !important;
}
.ant-menu.ant-menu-dark,
.ant-menu .ant-menu-dark {
  background-color: #2d2d2d !important;
}
.ant-menu.ant-menu-dark .ant-menu-item,
.ant-menu .ant-menu-dark .ant-menu-item {
  background-color: #2d2d2d;
}
.ant-menu.ant-menu-dark .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu .ant-menu-dark .ant-menu-submenu .ant-menu-submenu-title {
  background-color: #2d2d2d !important;
}
.ant-menu.ant-menu-dark .ant-menu-submenu .ant-menu-store.subscribe,
.ant-menu .ant-menu-dark .ant-menu-submenu .ant-menu-store.subscribe,
.ant-menu.ant-menu-dark .ant-menu-submenu .ant-menu-inline,
.ant-menu .ant-menu-dark .ant-menu-submenu .ant-menu-inline {
  background-color: #2d2d2d !important;
}
.ant-tabs .ant-tabs-bar.ant-tabs-top-bar {
  padding: 0 18px;
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 14px 0 13px;
}
.ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: normal;
}
.ant-select {
  font-size: 12px;
}
.ant-select .ant-select-selection-selected-value {
  font-weight: normal !important;
}
.ant-select-dropdown {
  font-size: 12px;
}
.ant-switch.ant-switch-checked {
  background-color: #00cc00;
}
.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px !important;
  border: none !important;
}
.ant-table-wrapper .ant-table.ant-table-small > .ant-table-content > .ant-table-body > table {
  padding: 0;
}
.ant-table-wrapper .ant-table.ant-table-small > .ant-table-content > .ant-table-body > table td {
  height: 40px;
}
.ant-table-wrapper .ant-table .ant-table-title {
  top: 0;
  padding: 13px 18px;
  font-size: 14px;
  color: #161616;
  border-bottom: 1px solid #f3f3f3;
  display: block;
  zoom: 1;
}
.ant-table-wrapper .ant-table .ant-table-title:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.ant-table-wrapper .ant-table .ant-table-thead {
  font-size: 12px;
}
.ant-table-wrapper .ant-table .ant-table-thead > tr > th {
  padding: 12px 18px;
  font-size: 12px;
  border: none;
  background-color: #fff !important;
}
.ant-table-wrapper .ant-table .ant-table-thead > tr > th > span {
  color: #161616;
}
.ant-table-wrapper .ant-table .ant-table-thead .react-resizable {
  position: relative;
  background-clip: padding-box;
  user-select: none;
}
.ant-table-wrapper .ant-table .ant-table-thead .react-resizable:hover .react-resizable-handle:before {
  content: "<>";
  position: absolute;
  top: 14px;
  color: #52a7fe;
}
.ant-table-wrapper .ant-table .ant-table-thead .react-resizable .react-resizable-handle {
  position: absolute;
  width: 22px;
  height: 100%;
  bottom: 0;
  right: 0;
  cursor: col-resize;
  z-index: 1;
}
.ant-table-wrapper .ant-table .ant-table-body table {
  table-layout: fixed;
  max-width: 100%;
}
.ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row {
  font-size: 12px;
}
.ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row:nth-child(odd) {
  background-color: #f6f7f8;
}
.ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row:nth-last-child(1) td {
  border-bottom: 1px solid #f6f7f8;
}
.ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row td {
  border-bottom: none;
}
@media screen and (max-height: 1080px) {
  .ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row td {
    padding: 11px 18px;
    height: 46px;
  }
}
@media screen and (max-height: 970px) {
  .ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row td {
    padding: 9px 18px;
    height: 41px;
  }
}
@media screen and (max-height: 940px) {
  .ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row td {
    padding: 9px 18px;
    height: 41px;
  }
}
@media screen and (max-height: 920px) {
  .ant-table-wrapper .ant-table .ant-table-body table tr.ant-table-row td {
    padding: 8px 18px;
    height: 40px;
  }
}
.ant-table-wrapper .ant-table .ant-table-footer {
  padding: 8px 16px;
}
.ant-table-wrapper .ant-table-placeholder {
  margin-top: 8px;
  font-size: 10px;
  border-top: none;
}
.ant-calendar-picker.ant-calendar-picker-small .ant-calendar-picker-input.ant-input-sm {
  height: 30px;
}
.ant-calendar-picker.ant-calendar-picker-small .ant-calendar-picker-input .ant-calendar-range-picker-separator {
  vertical-align: baseline;
}
.ant-pagination.ant-table-pagination {
  display: flex;
  padding: 0 18px;
  width: 100%;
}
.ant-pagination.ant-table-pagination .ant-pagination-total-text {
  flex: 1;
  font-size: 12px;
  line-height: 32px;
}
.ant-pagination.ant-table-pagination .ant-pagination-total-text span {
  padding-left: 10px;
}
.ant-pagination.ant-table-pagination .ant-pagination-prev {
  margin-left: 20px;
}
.ant-pagination.ant-table-pagination .ant-pagination-item,
.ant-pagination.ant-table-pagination .ant-pagination-prev,
.ant-pagination.ant-table-pagination .ant-pagination-next {
  min-width: 28px;
  height: 28px;
  line-height: 28px;
}
.ant-pagination.ant-table-pagination .ant-pagination-item .ant-pagination-item-link,
.ant-pagination.ant-table-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-table-pagination .ant-pagination-next .ant-pagination-item-link {
  height: 28px;
}
.ant-pagination.ant-table-pagination .ant-pagination-item .ant-pagination-item-link:before,
.ant-pagination.ant-table-pagination .ant-pagination-prev .ant-pagination-item-link:before,
.ant-pagination.ant-table-pagination .ant-pagination-next .ant-pagination-item-link:before,
.ant-pagination.ant-table-pagination .ant-pagination-item .ant-pagination-item-link:after,
.ant-pagination.ant-table-pagination .ant-pagination-prev .ant-pagination-item-link:after,
.ant-pagination.ant-table-pagination .ant-pagination-next .ant-pagination-item-link:after {
  height: 28px;
}
.anticon.ocms-option-node {
  margin-right: 15px;
}
.ant-spin .ant-spin-text {
  margin-top: 80px;
  font-size: 18px;
}
.ant-notification {
  font-size: 12px;
}
.ant-notification .ant-notification-notice-message {
  font-size: 14px;
}
.ant-notification .ant-notification-notice-description {
  font-size: 12px;
}
.ant-steps .ant-steps-item-description {
  font-size: 12px;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  -webkit-animation-name: none !important;
  animation-name: none !important;
}
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: none !important;
  animation-name: none !important;
}
.show-help-enter,
.show-help-appear {
  opacity: 1 !important;
}
.ant-tooltip-inner {
  max-width: 1000px !important;
  background-color: #fff !important;
  color: #2d2d2d !important;
  padding: 14px 20px !important;
  border-radius: 6px !important;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  border-top-color: #fff !important;
}
.ant-divider-inner-text {
  font-size: 12px;
  font-weight: normal;
}
/**
 * App layout structure:
 *
 *               html
 *                 |
 *               body
 *                 |
 *               #root (the root component of APP mounts on this DOM node)
 *                 |
 *             .ocms-body (only after user sign in)
 *                 |
 *        +-----------------+
 *   .ocms-side-bar       .ocms-main
 *        |                 |
 *   +----------------------------------+
 *   |         |         top bar        |
 *   |         |------------------------|
 *   |   menu  |                        |
 *   |         |         content        |  —— When we change the router to jump to another page,
 *   |         |                        |     only content part will be switched.
 *   +----------------------------------+
 *
 * For other components:
 * The Initialize, SignIn, etc. aren't follow the structure mentioned above
 * starting from #root. They are just forced to spread the whole screen simply.
 *
 * The minimum width we recommended for displaying every thing well is:
 * sidebar shrinks: ?? px,
 * sidebar expends: ?? px.
 */
body {
  width: 100%;
  height: 100%;
  color: #646464 !important;
}
body #ocms-app-root {
  position: relative;
  height: 100%;
  overflow-y: hidden;
}
body #ocms-app-root .ocms-body-wrapper {
  height: 100%;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper {
  display: flex;
  flex-direction: row;
  height: calc(100% - 50px);
  overflow-y: hidden;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper {
  flex: 1;
  position: relative;
  padding: 10px;
  min-width: 1280px;
  overflow-y: hidden;
  background-color: #f7f8fc;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper {
  margin-bottom: 10px;
  padding: 0 18px;
  height: 52px;
  line-height: 52px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 2px #eceff3;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper.no-bottom-margin {
  margin-bottom: 0;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper .ant-input-search {
  width: 200px;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper .ant-select-selection {
  width: 140px;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper .ocms-table-operation-button-box {
  float: right;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper .ocms-table-operation-button-box .ant-btn {
  margin-left: 12px;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper .ocms-table-operation-button-box .ant-btn:nth-child(1) {
  margin-left: 0;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper .ocms-table-operation-text {
  margin-left: 20px;
  font-size: 12px;
}
@media screen and (max-width: 600px) {
  body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-table-operation-wrapper .ocms-table-operation-button-box {
    display: none;
  }
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-main-content-wrapper {
  position: relative;
  height: calc(100vh - 132px);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 2px #eceff3;
}
body #ocms-app-root .ocms-body-wrapper .ocms-main-wrapper .ocms-content-wrapper .ocms-page-content .ocms-main-content-wrapper .ant-table-wrapper {
  min-width: 1000px;
}
.ocms-sign-in-wrapper {
  position: relative;
  min-height: 500px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: cover;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper {
  display: flex;
  flex-direction: row;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-banner-wrapper {
  position: relative;
  width: 400px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-banner-wrapper .ocms-sign-in-form-logo {
  margin: 60px auto 0;
  width: 133px;
  height: 50px;
  background-image: url("../images/logo.png");
  background-size: 100% 100%;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-banner-wrapper .platform-text {
  margin-top: 50px;
  font-size: 30px;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-banner-wrapper .welcome-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: lighter;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-banner-wrapper .platform-text,
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-banner-wrapper .welcome-text {
  letter-spacing: 5px;
  text-shadow: 1px 1px 2px black;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-banner-wrapper .copyright-text {
  position: absolute;
  bottom: 27px;
  width: 100%;
  font-size: 12px;
  color: #fff;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper {
  position: relative;
  padding: 50px;
  width: 400px;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.7);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box {
  margin-top: 30px;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box .ant-input {
  height: 50px;
  line-height: 50px;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box .ant-form-explain {
  margin: 4px 0;
  min-height: 20px;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box .ocms-sign-in-input-wrapper {
  margin-bottom: 0 !important;
  height: 75px;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box .verification-code-img {
  width: 150px;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box .ocms-sign-in-button-box {
  position: absolute;
  bottom: 30px;
  width: calc(100% - 100px);
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box .ocms-sign-in-button-box .ant-btn.ant-btn-primary {
  margin-top: 30px;
  width: 100%;
  height: 50px !important;
  line-height: 50px;
  font-size: 16px !important;
  background-color: #4c7de3;
}
.ocms-sign-in-wrapper .ocms-sign-in-content-wrapper .ocms-sign-in-form-wrapper .ocms-sign-in-form-box .ocms-sign-in-button-box .ant-btn.ant-btn-primary:hover {
  box-shadow: 1px 1px 3px 1px #4c7de3;
}
.ocms-sidebar-wrapper {
  width: 180px;
  min-width: 180px;
  height: 100%;
  background-color: #f7f8fc;
}
.ocms-sidebar-wrapper .ocms-sidebar-menu-wrapper {
  margin-top: 10px;
  margin-left: 10px;
  height: calc(100% - 10px - 10px);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 2px #eceff3;
}
.ocms-top-bar-wrapper {
  width: 100%;
  min-width: 1280px;
  height: 50px;
  font-size: 12px;
  color: #fff;
  text-shadow: 1px 1px 2px black;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("../images/top_bar_bg.png");
  background-size: cover;
}
.ocms-top-bar-wrapper .ocms-platform-copy-right-wrapper {
  float: left;
  height: 50px;
  line-height: 50px;
}
.ocms-top-bar-wrapper .ocms-platform-copy-right-wrapper .mis-name {
  margin-left: 10px;
}
.ocms-top-bar-wrapper .ocms-platform-copy-right-wrapper .copy-right {
  margin-left: 117px;
}
.ocms-top-bar-wrapper .ocms-top-info-wrapper {
  float: right;
  padding-right: 10px;
  height: 50px;
  line-height: 50px;
}
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper {
  user-select: none;
}
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper .ocms-singed-in-user,
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper .ocms-reset-password,
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper .ocms-sign-out {
  margin-left: 10px;
}
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper .ocms-singed-in-user:hover,
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper .ocms-reset-password:hover,
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper .ocms-sign-out:hover {
  cursor: pointer;
  color: #52a7fe;
}
.ocms-top-bar-wrapper .ocms-top-info-wrapper .ocms-sign-in-user-wrapper .ocms-singed-in-user {
  margin-left: 0;
}
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-header {
  background-color: transparent;
  border: none;
  position: relative;
}
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-header .ant-drawer-title {
  font-size: 14px;
  color: #646464;
}
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-header:after {
  content: " ";
  position: absolute;
  bottom: 0;
  display: block;
  width: 208px;
  height: 1px;
  border-top: 1px solid #d9d9d9;
}
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-body {
  position: relative;
  height: calc(100% - 55px);
  font-size: 12px;
}
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-body p {
  margin-bottom: 20px;
  color: #646464;
}
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-body p.ocms-top-bar-drawer-action-btn {
  cursor: pointer;
  user-select: none;
}
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-body p.ocms-top-bar-drawer-action-btn:active,
.ocms-top-bar-drawer .ant-drawer-wrapper-body .ant-drawer-body p.ocms-top-bar-drawer-action-btn:hover {
  color: #52a7fe;
}
.ocms-dashboard .ocms-dashboard-row {
  display: flex;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-overview,
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-page-view,
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-chart {
  margin-top: 0;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 2px #eceff3;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-overview > header,
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-page-view > header,
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-chart > header {
  padding: 9px 18px;
  font-size: 14px;
  color: #161616;
  border-bottom: 1px solid #f3f3f3;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-overview {
  flex: 1;
  height: 140px;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-overview .ocms-dashboard-overview-content {
  padding: 0 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-overview .ocms-dashboard-overview-content .ocms-dashboard-overview-content-item {
  margin-top: 20px;
  width: 16.66666667%;
  min-width: 16.66666667%;
  text-align: left;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-overview .ocms-dashboard-overview-content .ocms-dashboard-overview-content-item:hover {
  color: #52a7fe;
  cursor: pointer;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-page-view {
  flex: 1.24;
  margin-top: 10px;
  height: 370px;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-page-view.site-user {
  flex: 2.5;
  margin-left: 10px;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-page-view .ocms-dashboard-page-view-table-wrapper .ant-table-wrapper .ant-table-placeholder {
  border-bottom: none !important;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-chart {
  flex: 1;
  margin-top: 10px;
  height: calc((100vh - 50px - 10px - 140px - 10px - 40px - 10px - 10px - 370px - 10px));
  min-height: 257px;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-chart.vv,
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-chart.ip {
  margin-left: 10px;
}
.ocms-dashboard .ocms-dashboard-row .ocms-dashboard-chart .ocms-chart-adaptive-height {
  text-align: center;
  height: calc(100% - 40px);
}
.ocms-dashboard .ocms-dashboard-statistics-period {
  margin-top: 10px;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #161616;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 2px #eceff3;
}
.ocms-dashboard .ocms-dashboard-statistics-period .title {
  padding-right: 20px;
}
