@mainMenuWith: 180px;

.ocms-sidebar-wrapper {
    width: @mainMenuWith;
    min-width: @mainMenuWith;
    height: 100%;
    background-color: @page-bg;
    .ocms-sidebar-menu-wrapper {
        margin-top: 10px;
        margin-left: 10px;
        height: ~"calc(100% - 10px - 10px)";
        background-color: @white;
        border-radius: @radius;
        .ocms-box-shadow(1px, 1px, 2px, 2px, #eceff3);
    }
}