/**
 * App layout structure:
 *
 *               html
 *                 |
 *               body
 *                 |
 *               #root (the root component of APP mounts on this DOM node)
 *                 |
 *             .ocms-body (only after user sign in)
 *                 |
 *        +-----------------+
 *   .ocms-side-bar       .ocms-main
 *        |                 |
 *   +----------------------------------+
 *   |         |         top bar        |
 *   |         |------------------------|
 *   |   menu  |                        |
 *   |         |         content        |  —— When we change the router to jump to another page,
 *   |         |                        |     only content part will be switched.
 *   +----------------------------------+
 *
 * For other components:
 * The Initialize, SignIn, etc. aren't follow the structure mentioned above
 * starting from #root. They are just forced to spread the whole screen simply.
 *
 * The minimum width we recommended for displaying every thing well is:
 * sidebar shrinks: ?? px,
 * sidebar expends: ?? px.
 */

body {
    width: 100%;
    height: 100%;
    color: @text !important;
    #ocms-app-root {
        position: relative;
        height: 100%;
        overflow-y: hidden;
        .ocms-body-wrapper {
            height: 100%;
            .ocms-main-wrapper {
                display: flex;
                flex-direction: row;
                height: ~"calc(100% - 50px)";
                // Prohibit the appearance of native scrollbar.
                overflow-y: hidden;
	            .ocms-sidebar-wrapper {
					// See styles in sideBar.less file.
		            // ...
	            }
                .ocms-content-wrapper {
	                flex: 1;
                    position: relative;
                    padding: 10px;
                    min-width: 1280px;
                    overflow-y: hidden;
                    background-color: @page-bg;
                    .ocms-page-content {
                        .ocms-table-operation-wrapper {
                            margin-bottom: 10px;
                            padding: 0 18px;
                            height: 52px;
                            line-height: 52px;
                            background-color: @white;
                            border-radius: @radius;
                            &.no-bottom-margin {
                                margin-bottom: 0;
                            }
                            .ocms-box-shadow(1px, 1px, 2px, 2px, #eceff3);
                            .ant-input-search {
                                width: 200px;
                            }
                            .ant-select-selection {
                                width: 140px;
                            }
                            .ocms-table-operation-button-box {
                                float: right;
                                .ant-btn {
                                    margin-left: 12px;
                                    &:nth-child(1) {
                                        margin-left: 0;
                                    }
                                }
                            }
                            .ocms-table-operation-text {
                                margin-left: 20px;
                                font-size: @xsFont;
                            }
                            @media screen and (max-width: 600px) {
                                .ocms-table-operation-button-box {
                                    display: none;
                                }
                            }
                        }
                        .ocms-main-content-wrapper {
                            position: relative;
                            height: ~"calc(100vh - 132px)";
                            background-color: @white;
                            border-radius: @radius;
                            .ocms-box-shadow(1px, 1px, 2px, 2px, #eceff3);
                            .ant-table-wrapper {
                                min-width: 1000px;
                            }
                        }
                    }
                }
            }
        }
    }
}