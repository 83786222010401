.ocms-dashboard {
	.ocms-dashboard-row {
		display: flex;
		.ocms-dashboard-overview,
		.ocms-dashboard-page-view,
		.ocms-dashboard-chart {
            margin-top: 0;
            background-color: @white;
			border: none;
			border-radius: 6px;
            .ocms-box-shadow(1px, 1px, 2px, 2px, #eceff3);
            & > header {
                padding: 9px 18px;
			    font-size: @sFont;
			    color: @title;
			    border-bottom: 1px solid @light-border;
            }
		}
		.ocms-dashboard-overview {
			flex: 1;
			height: 140px;
			.ocms-dashboard-overview-content {
				padding: 0 18px;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				.ocms-dashboard-overview-content-item {
					margin-top: 20px;
					width: 100 ./ 6%;
					min-width: 100 ./ 6%;
					text-align: left;
					&:hover {
						color: @light-blue;
						cursor: pointer;
					}
				}
			}
		}
		.ocms-dashboard-page-view {
			flex: 1.24;
			margin-top: 10px;
			height: 370px;
			&.site-user {
				flex: 2.5;
				margin-left: 10px;
			}
			.ocms-dashboard-page-view-table-wrapper {
				.ant-table-wrapper {
					.ant-table-placeholder {
						border-bottom: none !important;
					}
				}
			}
		}
		.ocms-dashboard-chart {
            flex: 1;
			margin-top: 10px;
            height: ~"calc((100vh - 50px - 10px - 140px - 10px - 40px - 10px - 10px - 370px - 10px))";
			min-height: 257px;
			&.vv,
			&.ip {
				margin-left: 10px;
			}
            .ocms-chart-adaptive-height {
                text-align: center;
                height: ~"calc(100% - 40px)";
            }
        }
	}
	.ocms-dashboard-statistics-period {
		margin-top: 10px;
		padding: 0 15px;
		height: 40px;
		line-height: 40px;
	    font-size: @sFont;
	    color: @title;
        background-color: @white;
		border: none;
		border-radius: 6px;
        .ocms-box-shadow(1px, 1px, 2px, 2px, #eceff3);
		.title {
			padding-right: 20px;
		}
	}
}