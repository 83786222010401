.ocms-clear-fix () {
    display: block;
    zoom: 1;

    &:after {
        content: " ";
        display: block; // The clear attribute only take effect on block element.
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.ocms-box-shadow (@h: 0, @v: 0, @blur: 1px, @spread: 1px, @color: #e1e1e1) {
    box-shadow: @h @v @blur @spread @color;
}

.ocms-text-overflow-ellipsis () {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ocms-multiple-line-text-overflow-ellipsis (@line: 3) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-clamp: @line;
	box-orient: vertical;
	-webkit-line-clamp: @line;
	-webkit-box-orient: vertical;
	-moz-line-clamp: @line;
	-moz-box-orient: vertical;
	-ms-line-clamp: @line;
	-ms-box-orient: vertical;
}

.ocms-letter-spacing (@num: 3px) {
    letter-spacing: @num;
}

.ocms-placeholder-color (@color: #6f75b6) {
	&::-webkit-input-placeholder {
		color: @color;
	}
	&::-moz-placeholder {
		color: @color;
	}
	&:-ms-input-placeholder {
		color: @color;
	}
}