.ocms-sign-in-wrapper {
	position: relative;
	min-height: 500px;
    height: 100vh;
	display: flex;
    justify-content: center;
	align-items: center;
	overflow: hidden;
    background-size: cover;
	.ocms-sign-in-content-wrapper {
        display: flex;
        flex-direction: row;
        .ocms-sign-in-banner-wrapper {
            position: relative;
            width: 400px;
            text-align: center;
            font-size: @xxlFont;
            color: @white;
            background-color: rgba(0, 0, 0, 0.5);
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            .ocms-sign-in-form-logo {
                margin: 60px auto 0;
                width: 133px;
                height: 50px;
                background-image: url("../../images/logo.png");
                background-size: 100% 100%;
            }
            .platform-text {
                margin-top: 50px;
                font-size: 30px;
            }
            .welcome-text {
                margin-top: 20px;
                font-size: @lFont;
                font-weight: lighter;
            }
            .platform-text,
            .welcome-text {
                letter-spacing: 5px;
                text-shadow: 1px 1px 2px black;
            }
            .copyright-text {
                position: absolute;
                bottom: 27px;
                width: 100%;
                font-size: @xsFont;
                color: @white;
            }
        }
		.ocms-sign-in-form-wrapper {
			position: relative;
            padding: 50px;
            width: 400px;
            height: 400px;
            background-color: rgba(255, 255, 255, 0.7);
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            .ocms-sign-in-form-box {
                margin-top: 30px;
                .ant-input {
                    height: 50px;
                    line-height: 50px;
                }
                .ant-form-explain {
                    margin: 4px 0;
                    min-height: 20px;
                }
                .ocms-sign-in-input-wrapper {
                    margin-bottom: 0 !important;
                    height: 50px + 20px + 5px; // 20px is the height of form explain.
                }
                .verification-code-img {
                    width: 150px;
                    height: 50px;
                    border: 1px solid #d9d9d9;
                    border-radius: 6px;
                    background-color: @white;
                    cursor: pointer;
                }
                .ocms-sign-in-button-box {
                    position: absolute;
                    bottom: 30px;
                    width: ~"calc(100% - 100px)";
                    .ant-btn {
                        &.ant-btn-primary {
                            margin-top: 30px;
                            width: 100%;
                            height: 50px !important;
                            line-height: 50px;
                            font-size: @mFont ~"!important";
                            background-color: #4c7de3;
                            &:hover {
                                .ocms-box-shadow(1px, 1px, 3px, 1px, #4c7de3);
                            }
                        }
                    }
                }
            }
		}
	}
}